import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.scss';
import 'jquery';
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import AppRouter from './Layout/App.Router';
import reportWebVitals from './reportWebVitals';
import './css/website.scss'
import 'font-awesome/css/font-awesome.min.css'

const root = ReactDOM.createRoot(document.getElementById('carepayapp'));
root.render(
  <React.StrictMode>
    <AppRouter />
 </React.StrictMode>
);

reportWebVitals();
